<template>
  <div class="suivi-independant">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-63-px">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <b-form-checkbox
            v-show="switchMonth == false"
            v-model="switchMonth"
            @change="handleChangeSwitch('switchWeek')"
            name="check-button"
            switch
            class="check-th"
            :value="true"
            :unchecked-value="false"
          >
            <b class="mt-1 switch-label-style">Par Mois </b>
          </b-form-checkbox>
          <b-form-checkbox
            v-show="switchWeek == false"
            v-model="switchWeek"
            @change="handleChangeSwitch('switchMonth')"
            name="check-button"
            switch
            class="check-th"
            :value="true"
            :unchecked-value="false"
          >
            <b class="mt-1 switch-label-style">Par Semaine </b>
          </b-form-checkbox>
        </div>
        <div class="box-label-champ mr-2" v-if="switchMonth == true">
          <div class="label-box-style  w-46-px">
            <span class="title-tabel">Mois</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterMonth"
            :options="getListMonth"
            @change="handleChangeMonth"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2" v-if="switchWeek == true">
          <div class="label-box-style  w-63-px">
            <span class="title-tabel">Semaine</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterSemaine"
            :options="ComputedListWeek"
            @change="handleChangeSemaine"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-63-px">
            <span class="title-tabel">Type</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select"
            v-model="filterType"
            :options="[{ value: null, text: 'tous' }, ...ComputedgetAllType]"
            value-field="value"
            text-field="text"
            @change="handleChangeType"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Utilisateur</span>
          </div>

          <SelectComponent
            :options="ComputedgetAllEmployee"
            :value="filterUser"
            label="full_name"
            champName="utilisateur"
            filterName="filterUser"
            :change="handleChange"
            :track-by="'full_name'"
            placeholder="Rechercher"
            :searchable="true"
            :showLabels="false"
            classToUse="select-vue-component-style bg-select-vue"
          />
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  ">
            <span class="title-tabel">Statut</span>
          </div>

          <b-form-select
            class="b-form-select-new-style w-1 bg-select"
            v-model="filterValidation"
            :options="validationList"
            value-field="value"
            text-field="text"
            @change="handleChange"
          ></b-form-select>
        </div>
        <div v-if="getChargementSuiviIndependant" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="box-end-position">
          <b-button
            v-if="cantExport(this.$options.name)"
            size="sm"
            class="button-export-style  ml-2"
            @click="exportFiles"
            >Export</b-button
          >
        </div>
      </div>
    </div>
    <div class="body-suivi-independant">
      <div class="table-rapport-style w-100-p">
        <b-table
          responsive
          show-empty
          id="my-table"
          class="custom-table-style tabelSuiviOndependant  table-header "
          :items="computedgetuserSalary"
          :fields="fields"
          bordered
          sticky-header
          hover
          head-variant="light"
          :tbody-tr-class="rowClass"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template #thead-top="">
            <b-tr>
              <b-th
                variant="danger"
                colspan="5"
                class=" th-rouge-bg-color th-border-raduis-left"
                >AAF INDEPENDANT</b-th
              >
              <b-th
                variant="primary"
                colspan="11"
                class="th-jaune-bg-color th-border-raduis-rigth  "
              >
                COMTABILITE</b-th
              >
            </b-tr>
          </template>
          <template v-slot:cell(color)> </template>
          <template v-slot:cell(nom_crm)="data">
            {{ data.item.nom_crm }}
          </template>
          <template v-slot:cell(date_facture)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="date_facture"
              :item="data.item"
              :value="data.item.date_facture"
              type="date"
              editableType="datetime"
              :updateFunction="updateSuiviIndependent"
            />
          </template>
          <template v-slot:cell(date_envoi)="data">
            {{ data.item.date_envoi }}
          </template>

          <!-- <template v-slot:cell(mail)="data">
            {{ data.item.employee.email }}
          </template>

          <template v-slot:cell(type)="data">
            {{ data.item.type }}
          </template> -->

          <template v-slot:cell(mois)="data">
            {{ data.item.mois }}
          </template>
          <template v-slot:cell(num_aaf)="data">
            {{ data.item.num_aaf }}
          </template>
          <template v-slot:cell(nom_commercial)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="nom_commercial"
              :item="data.item"
              :value="data.item.nom_commercial"
              type="text"
              editableType="input"
              :updateFunction="updateSuiviIndependent"
              :defaultColor="true"
            />
          </template>
          <template v-slot:cell(montant_ht)="data">
            {{ data.item.montant_ht }}
          </template>
          <template v-slot:cell(s_montant_ht)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="s_montant_ht"
              :item="data.item"
              :value="data.item.s_montant_ht"
              type="text"
              editableType="input"
              :updateFunction="updateSuiviIndependent"
              :defaultColor="true"
            />
          </template>
          <template v-slot:cell(s_montant_ttc)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="s_montant_ttc"
              :item="data.item"
              :value="data.item.s_montant_ttc"
              type="text"
              editableType="input"
              :updateFunction="updateSuiviIndependent"
              :defaultColor="true"
            />
          </template>
          <template v-slot:cell(comptabilse)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="comptabilse"
              editableType="select"
              :optionsSelect="[{ full_name: 'oui' }, { full_name: 'non' }]"
              :item="data.item"
              :value="data.item.comptabilse"
              type="text"
              :updateFunction="updateSuiviIndependent"
            />
          </template>
          <template v-slot:cell(saisie_le)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="saisie_le"
              :item="data.item"
              :value="data.item.saisie_le"
              type="date"
              editableType="datetime"
              :updateFunction="updateSuiviIndependent"
            />
          </template>
          <template v-slot:cell(a_payer_par)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="a_payer_par"
              :item="data.item"
              :value="data.item.a_payer_par"
              type="text"
              editableType="input"
              :updateFunction="updateSuiviIndependent"
              :defaultColor="true"
            />
          </template>

          <template v-slot:cell(socite_facture)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="socite_facture"
              :item="data.item"
              :value="data.item.socite_facture"
              type="text"
              editableType="input"
              :updateFunction="updateSuiviIndependent"
              :defaultColor="true"
            />
          </template>
          <template v-slot:cell(facture_recue)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="facture_recue"
              :item="data.item"
              :value="data.item.facture_recue"
              type="text"
              editableType="input"
              :updateFunction="updateSuiviIndependent"
              :defaultColor="true"
            />
          </template>
          <template v-slot:cell(num_facture)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="num_facture"
              :item="data.item"
              :value="data.item.num_facture"
              type="text"
              editableType="input"
              :updateFunction="updateSuiviIndependent"
              :defaultColor="true"
            />
          </template>

          <template v-slot:cell(payed_at)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="payed_at"
              :item="data.item"
              :value="data.item.payed_at"
              type="date"
              editableType="datetime"
              :updateFunction="updateSuiviIndependent"
            />
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowSuiviIndependant"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EditableInput from '../component/EditableInput.vue';
import moment from 'moment';
export default {
  name: 'Suivi-indépendants',
  data() {
    return {
      switchMonth: false,
      switchWeek: true,
      filterMonth: 1,
      filterUser: { full_name: 'tous', id: null },
      filterType: null,
      filteryear: null,
      filterSemaine: null,
      filterValidation: null,
      ListYear: [],
      ListWeek: [],
      page: 1,
      perPage: 20,
      validationList: [
        { value: null, text: 'tous' },
        { value: 'valid', text: 'appel à facturation validé' },
        { value: 'paye', text: 'Payé' }
      ],

      fields: [
        {
          key: 'mois',
          label: 'MOIS'
        },
        {
          key: 'nom_crm',
          label: 'NOM CRM'
        },
        // { key: 'mail', label: 'E-mail' },
        // { key: 'type', label: 'Type' },
        { key: 'num_aaf', label: 'N° AAF' },

        {
          key: 'date_envoi',
          label: 'DATE ENVOI AAF'
        },
        {
          key: 'montant_ht',
          label: 'MONTANT HT'
        },
        {
          key: 'nom_commercial',
          label: 'NOM COMMERCIAL'
        },
        {
          key: 'socite_facture',
          label: 'SOCITÉ FACTURÉE'
        },
        {
          key: 'facture_recue',
          label: 'FACTURE REÇUE '
        },
        {
          key: 'num_facture',
          label: 'N° FACTURE '
        },
        {
          key: 'date_facture',
          label: 'DATE FACT '
        },
        {
          key: 's_montant_ht',
          label: 'MONTANT HT '
        },
        {
          key: 's_montant_ttc',
          label: 'MONTANT TTC '
        },
        {
          key: 'comptabilse',
          label: 'COMPTABLISÉ ( oui / non ) '
        },
        { key: 'saisie_le', label: 'SAISIE LE ' },
        {
          key: 'a_payer_par',
          label: 'A PAYER PAR '
        },
        {
          key: 'payed_at',
          label: 'DATE PAIEMENT'
        }
      ],
      perPageList: [
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        { value: 100, text: 100 }
      ]
    };
  },
  methods: {
    ...mapActions([
      'getSuiviIndependantFiltred',
      'getDepotForUser',
      'updateSuiviIndependent',
      'getOnlineType',
      'getOnlineSalaryEmployeeBytype',
      'exportExcelSuiviIndependant'
    ]),
    handleChangeMonth() {
      this.handleFilter();
    },
    handleChangeSwitch(item) {
      if (item == 'switchMonth') {
        this.switchMonth = false;
      } else {
        this.switchWeek = false;
      }
      this.filterUser = { full_name: 'tous', id: null };
      this.filterType = null;
      this.handleFilter();
      this.hendleGetTypeAndUser();
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      let index = this.getSuiviIndependantTable.findIndex(i => i.id == item.id);
      if (
        index != -1 &&
        index < this.getSuiviIndependantTable.length &&
        this.getSuiviIndependantTable[index] &&
        this.getSuiviIndependantTable[index + 1]
      ) {
        if (
          this.getSuiviIndependantTable[index].semaine !=
          this.getSuiviIndependantTable[index + 1].semaine
        ) {
          return 'ligneWithBorder';
        } else {
          return 'ligneEnCourDeValidation';
        }
      } else {
        return 'ligneEnCourDeValidation';
      }
    },
    hendleGetTypeAndUser() {
      this.getOnlineType({
        year: this.filteryear,
        semaine: this.filterSemaine,
        salarie: 'non',
        month: this.filterMonth,
        switchMonth: this.switchMonth
      });
      this.getOnlineSalaryEmployeeBytype({
        year: this.filteryear,
        semaine: this.filterSemaine,
        type: this.filterType,
        salarie: 'non',
        month: this.filterMonth,
        switchMonth: this.switchMonth
      });
    },
    handleChange(payload) {
      if (payload) {
        this[payload.champName] = payload.value;
      }
      this.page = 1;
      this.hendleGetTypeAndUser();
      this.handleFilter();
    },
    handleChangeSemaine(payload) {
      if (payload) {
        this[payload.champName] = payload.value;
      }
      this.page = 1;
      this.filterUser = { full_name: 'tous', id: null };
      this.filterType = null;
      this.hendleGetTypeAndUser();

      this.handleFilter();
    },
    handleChangeYear() {
      this.filterUser = { full_name: 'tous', id: null };
      this.filterType = null;
      this.page = 1;
      var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
      this.ListWeek = [];
      for (var week = 1; week <= totalWeek; week++) {
        this.ListWeek.push({ value: 's' + week, text: week });
      }
      this.hendleGetTypeAndUser();

      this.handleFilter();
    },
    handleChangeType() {
      this.page = 1;
      this.filterUser = { full_name: 'tous', id: null };
      this.getOnlineSalaryEmployeeBytype({
        year: this.filteryear,
        semaine: this.filterSemaine,
        type: this.filterType,
        salarie: 'non'
      });
      this.getDepotForUser({ type: this.filterType });
      this.handleFilter();
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    handleFilter() {
      this.getSuiviIndependantFiltred({
        semaine: this.filterSemaine,
        employee_id: this.filterUser,
        year: this.filteryear,
        page: this.page,
        validation: this.filterValidation,
        type: this.filterType,
        perPage: this.perPage,
        month: this.filterMonth,
        switchMonth: this.switchMonth
      });
    },
    async exportFiles() {
      await this.exportExcelSuiviIndependant({
        semaine: this.filterSemaine,
        year: this.filteryear,
        filterRegie: this.filterRegie,
        page: this.page,
        per_page: this.perPage,
        validation: this.filterValidation
      });
    }
  },
  computed: {
    ...mapGetters([
      'getAllEmployee',
      'getChargementSuiviIndependant',
      'getTotalRowSuiviIndependant',
      'getSuiviIndependantTable',
      'getOnlineTypeEmloyees',
      'getActiveEmployyes',
      'cantExport',
      'cantUpdate',
      'getListMonth'
    ]),
    computedCantUpdate() {
      return this.cantUpdate(this.$options.name);
    },
    ComputedgetAllType() {
      return this.getOnlineTypeEmloyees.map(item => {
        return {
          value: item,
          text: item
        };
      });
    },
    ComputedListWeek() {
      return [{ value: null, text: 'tous' }, ...this.ListWeek];
    },
    ComputedgetAllEmployee() {
      return [{ id: null, full_name: 'tous' }, ...this.getActiveEmployyes];
    },
    computedgetuserSalary() {
      // if (this.filterValidation != null) {
      //   return this.getSuiviIndependantTable.filter(
      //     item => item.validation == this.filterValidation
      //   );
      // }
      return this.getSuiviIndependantTable;
    }
  },
  components: {
    SelectComponent: () => import('../SelectComponent.vue'),
    EditableInput
  },
  mounted() {
    var courantYear = moment().year();
    this.filteryear = courantYear;
    this.filterSemaine = 's' + moment().isoWeek();
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
    for (var week = 1; week <= totalWeek; week++) {
      this.ListWeek.push({ value: 's' + week, text: week });
    }
    this.hendleGetTypeAndUser();

    this.getDepotForUser({ type: this.filterType });
    this.handleFilter();
  }
};
</script>

<style scoped lang="scss">
.tabelSuiviOndependant {
  height: calc(100vh - 232px);
  max-height: calc(100vh - 151px);
  margin-bottom: 0px;
}
.body-suivi-independant {
  margin-top: 5px;
  height: calc(100vh - 106px);
}
.sub-menu .body .body-suivi-independant {
  height: calc(100vh - 175px);
}

.suivi-independant {
  margin: 0px;
  width: 100%;
  padding: 10px;
  display: block;
  height: calc(100vh - 60px);
  overflow: hidden;
  .entete {
    width: 100%;
    .chargement {
      font-size: 8px;
      margin-left: 5px;
      .spinner-border {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}
</style>
<style lang="scss">
.tabelSuiviOndependant {
  td,
  th {
    white-space: nowrap !important;
    width: auto !important;
    min-width: auto;
    padding: 6px 5px !important;
  }
  th {
    font-size: 9.5px !important;
  }
  td {
    font-size: 10px;
  }
  .th-rouge-bg-color {
    background-color: #e9999c !important;
  }
  .th-jaune-bg-color {
    background-color: #f5c12dd4 !important;
  }
  .th-border-raduis-left {
    border-radius: 10px 0px 0px 0px;
  }
  .th-border-raduis-rigth {
    border-radius: 0px 10px 0px 0px;
  }
}
</style>
